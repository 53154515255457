import { getParsedCookie, setCookie } from '@ridi-web/common';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useAdultExclude = (): [boolean, (isAdultExcluded: boolean) => void] => {
  const router = useRouter();
  const [isAdultExcludedState, setIsAdultExcludedState] = useState(true);

  const setIsAdultExcluded = (value: boolean) => {
    setCookie('adult_exclude', value ? 'y' : 'n', { path: '/', sameSite: 'lax' });
    setIsAdultExcludedState(value);
  };

  // Run on client to get cookies
  useEffect(() => {
    if (router.query.adult_exclude) {
      setIsAdultExcluded(router.query.adult_exclude === 'y');
      return;
    }

    const cookie = getParsedCookie(document.cookie);
    setIsAdultExcludedState(cookie?.adult_exclude === 'y');
  }, [router.query.adult_exclude]);

  return [isAdultExcludedState, setIsAdultExcluded];
};
