import { css, SerializedStyles } from '@emotion/react';

import { BreakPoint, nonTouchDevice, orBelow } from '@/components/styles/media';
import { RIDITheme } from '@/components/styles/themes';

export const topItemsStyle = (theme: RIDITheme): SerializedStyles => css`
  display: flex;
  align-items: center;
  min-width: 0;
  overflow: auto;

  color: ${theme.colors.fillPrimary};
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.01em;
  white-space: nowrap;

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      font-size: 15px;
      padding-right: 4px;
      line-height: 20px;
    `,
  )};
`;

export const hideOnMobile = css`
  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      display: none;
    `,
  )};
`;

export const topItemLinkStyle = css`
  display: flex;
  align-items: center;

  padding: 17px 12px;

  ${nonTouchDevice(css`
    &:hover {
      opacity: 0.4;
    }
  `)}

  ${orBelow(
    BreakPoint.DesktopSmall,
    css`
      padding: 13px 9px 11px;
    `,
  )};
`;

export const topItemSelectedLinkStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.blue};
  font-weight: 700;
  pointer-events: none;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
`;

export const disableClickWhileHydrationStyle = css`
  pointer-events: none;
`;

export const linkGenreHomeStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.fillPrimary};
`;

export const linkNotGenreHomeStyle = (theme: RIDITheme): SerializedStyles => css`
  color: ${theme.colors.fillSecondary};
`;
